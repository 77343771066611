import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
  Flex, Text, Icon, HStack, Box, useDisclosure,
  VStack, Image, Link, Drawer, DrawerOverlay, DrawerContent,
  DrawerCloseButton, DrawerBody, Button
} from "@chakra-ui/react";

import { HamburgerIcon } from "@chakra-ui/icons";
import { MdSettings } from 'react-icons/md'
import Logo from '../assets/logo1.png'
import { FiLogOut } from 'react-icons/fi';

import './_styles/style.css';

function Header(props) {
  const { user } = props.auth;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 800);
  const [isSidebar, setIsSidebar] = useState(window.innerWidth < 800);

  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(false); // Esconde o botão ao rolar
    } else {
      setIsVisible(true); // Mostra o botão ao voltar para o topo
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleResize = () => {
    const resize = window.innerWidth > 800 ? true : false;
    const btn = window.innerWidth < 800 ? true : false;

    setIsSidebarVisible(resize);
    setIsSidebar(btn)
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Box mb={4} px={4} display={{ md: "block" }}>
        <Flex
          as="header"
          w="100%"
          maxWidth={1300}
          h="20"
          mx="auto"
          my="6"
          px="6"
          align="center"
        >
          <Flex align="center" ml="auto" >

            {!isSidebarVisible && (
              <div className={isSidebar ? `drawer ${!isVisible ? 'hidden' : ''}` : ''}>
                <Flex ml="6" mt="10">
                  <Button ref={btnRef} onClick={onOpen}>
                    <HamburgerIcon />
                  </Button>
                </Flex>
              </div>
            )}

            <Drawer
              isOpen={isOpen}
              placement='left'
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                  <VStack
                    spacing="10"
                    mx="8"
                    pr="8"
                    py="1"
                    color="#004AAD"
                  >
                    <Links />
                  </VStack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>

            {isSidebarVisible && (
              <HStack
                spacing="10"
                mx="8"
                pr="8"
                py="1"
                color="#004AAD"
                borderRightWidth={1}
                borderColor="gray.500"
              >
                <Links />
              </HStack>
            )}

            <Link as={RouterLink} to='/config' display="flex" algin="center">

              <Flex align="center" mr="4">
                <Box mr="4" textAlign="right" >
                  <Text color="#004AAD">{user.username}</Text>
                  <Text color="#gray.500" fontSize="small" >{user.email}</Text>
                </Box>

                <Icon as={MdSettings} boxSize={6} color="#004AAD" />

              </Flex>

            </Link>

            <Link as={RouterLink} to='/login' display="flex" algin="center">
              <Icon as={FiLogOut} boxSize={6} color="#004AAD" />
            </Link>

          </Flex>

        </Flex>
      </Box>
    </>
  );
}

function Links() {
  return (
    <>
      <Link as={RouterLink} to='/' display="flex" algin="center">
        <Image
          src={Logo} w={250}
        />
      </Link>

      <Link as={RouterLink} to='/clients' display="flex" algin="center">
        <Text>Contas</Text>
      </Link>

      <Link as={RouterLink} to='/users' display="flex" algin="center">
        <Text>Usuários</Text>
      </Link>

      <Link as={RouterLink} to='/cards' display="flex" algin="center">
        <Text>Cartões</Text>
      </Link>

      <Link as={RouterLink} to='/exchanges' display="flex" algin="center">
        <Text>Cripto</Text>
      </Link>

      <Link as={RouterLink} to='/plans' display="flex" algin="center">
        <Text>Planos</Text>
      </Link>

      <Link as={RouterLink} to='/accounting' display="flex" algin="center">
        <Text>Contabilidade</Text>
      </Link>
    </>

  );

}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Header);
