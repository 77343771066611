import React, { useEffect, useState } from 'react';
import { Input, Box, Flex, Heading, Button, Icon, Table, Thead, Tr, Th, Td, Text, Checkbox, Tbody, HStack, Link, Skeleton, Select, SelectField, TableContainer, SimpleGrid, Stack, FormControl } from '@chakra-ui/react';
import { RiAddLine, RiPencilLine } from 'react-icons/ri';
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import api from '../../services/api';
import { formatCompletDate, formatValue } from '../../utils/format';

import { Link as RouterLink, useParams } from 'react-router-dom';
import { format, subDays } from 'date-fns';

import AuthLayout from '../_layouts/AuthLayout';

export default function AccountStatement({ jwt, user }) {
  const { at } = useParams();
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [statement, setStatement] = useState([]);
  const [select, setSelect] = useState(1000);
  const [initDate, setInitDate] = useState(format(subDays(Date.now(), 30), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(Date.now(), 'yyyy-MM-dd'));

  const STATUS_PAYMENTS = {
    'false': "Inativo",
    'true': "Ativo",
  };

  async function loadData() {
    setLoading(true);
    try {
      const responseAccount = await api.post(`/bo/transactions-by-period`, {
        'at': at,
        'pageNumber': 1,
        'pageSize': select,
        'startDate': initDate,
        'endDate': endDate
      }, {
        headers: {
          'Authorization': 'Bearer ' + jwt,
        }
      });

      if (responseAccount.data) {
        setStatement(responseAccount.data.statement);
        setBalance(responseAccount.data.balance);
        setLoading(false);
      };
    } catch (err) {
      setStatement([]);
    }
  };

  async function handleDate() {
    loadData();
  };

  useEffect(() => {
    loadData()
  }, [jwt]);


  return (
    <AuthLayout>
      <Flex w="100%" display="flex" flexDirection="column">
        <Box w='100%' mb={16} bg='gray.100' p='8' borderRadius={8}>

          <Flex flexDirection="row-reverse" mb="4">
            <Link as={RouterLink} to={`/clients/${at}`} colEnd={6} mr={2}>
              <Button colorScheme='blackAlpha'>Voltar a Conta</Button>
            </Link>
          </Flex>

          <SimpleGrid flex="1" gap="4"
            minChildWidth="200px" align="flex-start"
            mb={4}>

            <Heading size='lg' fontWeight='normal'>Extrato da Conta: {at}</Heading>
            <Heading size='lg' fontWeight='normal'>Saldo: {formatValue(balance)}</Heading>

            <Stack direction='column' align='left' mr='4'>
              <Text>Quantidade</Text>
              <Select borderColor='black' size='md' value={select} onChange={(e) => setSelect(e.target.value)}>
                <option value={1000}>1.000</option>
                <option value={5000}>5.000</option>
                <option value={10000}>10.000</option>
              </Select>
            </Stack>

            <Stack direction='column' align='left' mr='4'>
              <Text>Data de Inicio</Text>
              <Input borderColor='black' size='md' type='date' max={endDate} value={initDate} onChange={(e) => setInitDate(e.target.value)} />
            </Stack>

            <Stack direction='column' align='left' mr="4">
              <Text>Data Fim</Text>
              <Input borderColor='black' size='md' type='date' min={initDate} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </Stack>
          </SimpleGrid>

            <Flex flexDirection="row-reverse" mb="4">
              <Button type='submit' onClick={handleDate} colorScheme='blue'>Filtrar</Button>
            </Flex>

          <TableContainer>
            <Table colorScheme='gray.200'>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Descrição / Origem</Th>
                  <Th>Tipo</Th>
                  <Th>Data - Hora</Th>
                  <Th>Valor</Th>
                  <Th>Tarifa</Th>
                  <Th>Saldo</Th>
                </Tr>
              </Thead>

              {loading ?
                <Tbody>
                  <Tr>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                  </Tr>
                </Tbody>
                :
                <Tbody>
                  {statement.map((e) => {
                    return (
                      <Tr>
                        <Td>
                          {e.type == 'IN' ?
                            <Icon boxSize={6} color={'green'} as={FaChevronCircleDown} />
                            :
                            <Icon boxSize={6} color={'red'} as={FaChevronCircleUp} />
                          }
                        </Td>
                        <Td>
                          <Text fontWeight='bold'>{e.description}</Text>
                        </Td>
                        <Td>
                          {e.method}
                        </Td>
                        <Td>
                          {formatCompletDate(e.date)}
                        </Td>
                        <Td>
                          {formatValue(e.amount)}
                        </Td>
                        <Td>
                          {formatValue(e.fee)}
                        </Td>
                        <Td>
                          {formatValue(e.balance)}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              }

            </Table>
          </TableContainer>

        </Box>
      </Flex>
    </AuthLayout>
  )
}
