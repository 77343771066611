import React, { useState, useEffect } from 'react';
import { useToast, Box, Flex, Heading, Button, Icon, Table, Thead, Tr, Th, Td, Text, Checkbox, Tbody, HStack, Link, Image, TableContainer } from '@chakra-ui/react';
import { RiAddLine, RiCloseLine, RiPencilLine } from 'react-icons/ri';
import { MdBlock, MdCheck } from "react-icons/md";
import { Link as RouterLink } from 'react-router-dom';
import api from '../../services/api';
import { formatDate } from '../../utils/format';

import AuthLayout from '../_layouts/AuthLayout';
import { format, subDays } from 'date-fns';

export default function ExchangeList({ jwt, user }) {
  const [exchanges, setExchanges] = useState([]);
  const [at, setAt] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [startDate, setStartDate] = useState(format(subDays(Date.now(), 30), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(Date.now(), 'yyyy-MM-dd'));
  const toast = useToast();

  async function aproveExchange(id) {
    try {
      const response = await api.post('/bo/approve-exchange/', {
        exchangeId: id
      }, {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      })

      toast({
        title: 'Transação realizada.',
        description: 'Alteração realizada com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      console.log(id);
      console.log(response.data);
      loadData();

    } catch (err) {
      console.log(err.data)
      toast({
        title: 'Erro na operação.',
        description: err.response.data.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  async function reproveExchange(id) {
    try {
      const response = await api.post('/bo/deny-exchange/', {
        exchangeId: id
      }, {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      })

      toast({
        title: 'Transação negada.',
        description: 'Alteração realizada com sucesso',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      console.log(id);
      console.log(response.data);
      loadData();

    } catch (err) {
      console.log(err.data)
      toast({
        title: 'Erro na operação.',
        description: err.response.data.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  async function loadData() {
    try {
      const response = await api.post('/bo/exchanges-by-period', {
        'at': at,
        'pageNumber': pageNumber,
        'pageSize': pageSize,
        'startDate': startDate,
        'endDate': endDate
      }, {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      });

      if (response.data) {
        setExchanges(response.data);
      };
    } catch (err) {
      setExchanges([]);
    }
  };

  useEffect(() => {
    loadData()
  }, [jwt]);

  return (
    <AuthLayout>
      <Flex w="100%" display="flex" flexDirection="column">
        <Box mb={16} bg='gray.100' p='8' borderRadius={8}>
          <Flex mb='8' justify='space-between' align='center'>
            <Heading size='lg' color='#004AAD' fontWeight='normal'>Operações Exchange</Heading>
          </Flex>

          <TableContainer>
            <Table colorScheme='gray.200'>
              <Thead>
                <Tr>
                  <Th>Account</Th>
                  <Th>Tipo</Th>
                  <Th>Quantidade</Th>
                  <Th>Wallet</Th>
                  <Th>Status</Th>
                  <Th>Data da Operação</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {exchanges.map((e) => {
                  return (
                    <Tr>
                      <Td>
                        <Box>
                          <Text fontWeight='bold'>{e.account.name}</Text>
                          <Text fontWeight='bold' fontSize='sm'>{e.account.account_token}</Text>
                        </Box>
                      </Td>
                      <Td>
                        {e.type == "OUT" ? 'BUY' : 'SELL'}
                      </Td>
                      <Td>
                        {e.amount}
                      </Td>
                      <Td>
                        {e.wallet}
                      </Td>
                      <Td>
                        {e.status}
                      </Td>
                      <Td>
                        {formatDate(e.createdAt)}
                      </Td>
                      <Td>
                        {e.status == 'created' ?
                          <HStack>
                            <Button
                              size='sm'
                              fontSize='sm'
                              colorScheme='whatsapp'
                              leftIcon={<Icon as={MdCheck} />}
                              onClick={() => aproveExchange(e.id)}
                            >
                              Aprovar
                            </Button>
                            <Button
                              size='sm'
                              fontSize='sm'
                              colorScheme='red'
                              leftIcon={<Icon as={MdBlock} />}
                              onClick={() => reproveExchange(e.id)}
                            >
                              Reprovar
                            </Button>

                          </HStack>
                          : <></>
                        }

                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>

        </Box>
      </Flex>
    </AuthLayout>
  )
}
