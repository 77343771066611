import React, { useEffect, useState } from 'react';
import { Input, Box, Flex, Heading, Button, Icon, Table, Thead, Tr, Th, Td, Text, Checkbox, Tbody, HStack, Link, Skeleton, TableContainer, SimpleGrid } from '@chakra-ui/react';
import { RiAddLine, RiPencilLine } from 'react-icons/ri';
import api from '../../services/api';
import { formatDate } from '../../utils/format';

import { Link as RouterLink } from 'react-router-dom';

import AuthLayout from '../_layouts/AuthLayout';
import Pagination from '../../components/Pagination';

export default function AccountList({ jwt, user }) {
  const [at, setAt] = useState(null);
  const [plan, setPlan] = useState('');
  const [date, setDate] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const STATUS_PAYMENTS = {
    'false': "Inativo",
    'true': "Ativo",
  };

  async function handleAt() {
    loadData();
  };

  async function loadData() {
    try {
      const responseUsers = await api.post('/bo/list-account', {
        'at': at
      }, {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      });

      if (responseUsers.data) {
        setUsers(responseUsers.data);
      };
      setLoading(false);
    } catch (err) {
      setUsers([]);
      setLoading(true);
    }
  }

  useEffect(() => {
    loadData()
  }, [jwt]);

  return (
    <AuthLayout>
      <Flex w="100%" display="flex" flexDirection="column">

        <Box w='100%' mb={16} bg='gray.100' p='8' borderRadius={8}>
          <Flex mb='8' justify='space-between' align='center'>
            <SimpleGrid flex="1" minChildWidth="200px" align="flex-start" mb={4}>
              <Heading mt="5" size='lg' color='#004AAD' fontWeight='normal'>
                Contas Cadastradas
              </Heading>

              <Flex direction='column' align='left' mr='4'>
                <Text>Filtrar por Conta</Text>
                <Input borderColor='black' size='md' type='text'
                  value={at} onChange={(e) => setAt(e.target.value)}
                />
              </Flex>

              <Button mt="6" type='submit' onClick={handleAt} colorScheme='blue'>
                Filtrar
              </Button>
            </SimpleGrid>
          </Flex>

          <TableContainer>
            <Table colorScheme='gray.200'>
              <Thead>
                <Tr>
                  <Th>Conta</Th>
                  <Th>Data de cadastro</Th>
                  <Th>Documento</Th>
                  <Th>Status Conta</Th>
                  <Th>Acesso</Th>
                </Tr>
              </Thead>
              {loading ?
                <Tbody>
                  <Tr>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                    <Td>
                      <Skeleton height='50px' />
                    </Td>
                  </Tr>
                </Tbody>
                :
                <Tbody>
                  {users.map((e) => {
                    return (
                      <Tr>
                        <Td>
                          <Box>
                            <Text fontWeight='bold'>{e.company_name}</Text>
                            <Text fontWeight='sm'>{e.account_token}</Text>
                          </Box>
                        </Td>
                        <Td>
                          {formatDate(e.createdAt)}
                        </Td>
                        <Td>
                          {e.document}
                        </Td>
                        <Td>
                          {STATUS_PAYMENTS[e.status]}
                        </Td>
                        <Td>
                          <HStack spacing='2'>
                            <Link as={RouterLink} to={`/clients/${e.account_token}`} display="flex" algin="center">
                              <Button
                                as='a'
                                size='sm'
                                fontSize='sm'
                                colorScheme='blue'
                                leftIcon={<Icon as={RiPencilLine} />}
                              >
                                BO Conta
                              </Button>
                            </Link>
                          </HStack>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              }
            </Table>

          </TableContainer>


        </Box>

      </Flex>
    </AuthLayout>
  )
}
