import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Text, Icon, HStack, Box, Avatar, IconButton, useDisclosure, VStack, Image, Link, DrawerOverlay, Button, Drawer, DrawerContent, DrawerCloseButton, DrawerBody } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { RiNotificationLine } from 'react-icons/ri'
import Logo from '../assets/logo1.png'
import { BsInstagram } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';

export default function Header2() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 800);
  const [isSidebar, setIsSidebar] = useState(window.innerWidth < 800);

  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(false); // Esconde o botão ao rolar
    } else {
      setIsVisible(true); // Mostra o botão ao voltar para o topo
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleResize = () => {
    const resize = window.innerWidth > 800 ? true : false;
    const btn = window.innerWidth < 800 ? true : false;

    setIsSidebarVisible(resize);
    setIsSidebar(btn)
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Box mb={4} px={4}>
        <Flex
          as="header"
          w="100%"
          maxWidth={1300}
          h="20"
          mx="auto"
          my="6"
          px="6"
          align="center"
        >
          {!isSidebarVisible && (
            <div className={isSidebar ? `drawer` : ''}>
              <Flex ml="6" mt="10">
                <Button ref={btnRef} onClick={onOpen}>
                  <HamburgerIcon />
                </Button>
              </Flex>
            </div>
          )}

          <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerBody>
                <VStack
                  w="100%"
                  spacing="10"
                  mx="8"
                  mt="8"
                  pr="8"
                  py="1"
                  color="#004AAD"
                >
                  <Links />
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>

          {isSidebarVisible && (
            <HStack
              spacing="10"
              mx="8"
              pr="8"
              py="1"
              color="#004AAD"
            >
              <Links />
            </HStack>
          )}

        </Flex>
      </Box>
    </>
  );
};

function Links() {
  return (
    <>
      <Link as={RouterLink} to='/' display="flex" algin="center">
        <Image src={Logo} w={250} />
      </Link>

      <Link href="https://madribank.com.br" target='blank' display="flex" algin="center">
        <Text>Site MadriBank</Text>
      </Link>

      <Link href="https://app.madribank.com.br/new_user" target='blank' display="flex" algin="center">
        <Text>Criar conta</Text>
      </Link>

      <Link href="https://app.madribank.com.br" target='blank' display="flex" algin="center">
        <Text>Entrar na conta</Text>
      </Link>

      <Flex alignItems="center">
        <Text>Siga a MadriBank</Text>

        <Link href="https://www.instagram.com/madribank/" target='blank' ml={2}>
          <Flex bg="#005cff" boxSize={10} borderRadius={50} alignItems="center" justifyContent="center">
            <Icon as={BsInstagram} color="white" boxSize={6} />
          </Flex>
        </Link>

        <Link href="https://api.whatsapp.com/send/?phone=%2B5562992164177" target='blank' ml={2}>
          <Flex bg="#005cff" boxSize={10} borderRadius={50}
            alignItems="center" justifyContent="center"
            flexDirection={{ sm: "column", lg: 'row' }}
          >
            <Icon as={FaWhatsapp} color="white" boxSize={6} />
          </Flex>
        </Link>
      </Flex>
    </>
  );
}
